var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn-toggle', {
    staticClass: "mr-n12",
    attrs: {
      "mandatory": "",
      "borderless": "",
      "active-class": "active"
    },
    model: {
      value: _vm.sort,
      callback: function ($$v) {
        _vm.sort = $$v;
      },
      expression: "sort"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--dot",
    attrs: {
      "value": "reviewedAt",
      "text": "",
      "x-small": ""
    }
  }, [_vm._v("최신순")]), _c('v-btn', {
    staticClass: "v-btn--dot",
    attrs: {
      "value": "meta.starsAverage.total",
      "text": "",
      "x-small": ""
    }
  }, [_vm._v("추천순")]), _c('v-btn', {
    staticClass: "v-btn--dot",
    attrs: {
      "value": "meta.reviewsCount.total",
      "text": "",
      "x-small": ""
    }
  }, [_vm._v("후기순")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }