var render = function render(){
  var _vm$location, _vm$location2, _vm$location3, _vm$location4, _vm$location5, _vm$location6, _vm$location7, _vm$location8, _vm$location9, _vm$location10;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "pr-md-30"
  }, [_c('v-responsive', {
    staticClass: "rounded-xl",
    attrs: {
      "aspect-ratio": 656 / 390
    }
  }, [_c('location-view-map', _vm._b({}, 'location-view-map', {
    location: _vm.location
  }, false))], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "mb-4 mr-n14 mb-md-20"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', {
    staticClass: "font-size-20 font-size-md-32 grey-2--text"
  }, [_vm._v(_vm._s((_vm$location = _vm.location) === null || _vm$location === void 0 ? void 0 : _vm$location.name))])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "transparent",
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.share
    }
  }, [_c('i', {
    staticClass: "icon icon-open-in-new"
  })]), _c('v-btn', {
    staticClass: "transparent",
    attrs: {
      "icon": "",
      "href": `tel:+${(_vm$location2 = _vm.location) === null || _vm$location2 === void 0 ? void 0 : _vm$location2.phone}`,
      "disabled": !((_vm$location3 = _vm.location) !== null && _vm$location3 !== void 0 && _vm$location3.phone)
    }
  }, [_c('i', {
    staticClass: "icon icon-phone-outline"
  })])], 1)], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "py-18 py-md-22"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [!!((_vm$location4 = _vm.location) !== null && _vm$location4 !== void 0 && _vm$location4.phone) ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "mr-10"
  }, [_c('i', {
    staticClass: "icon icon-phone-outline-sm"
  })]), _c('span', {
    staticClass: "font-size-14 font-size-md-16 grey-2--text"
  }, [_vm._v(_vm._s((_vm$location5 = _vm.location) === null || _vm$location5 === void 0 ? void 0 : _vm$location5.phone))])])]) : _vm._e(), !!((_vm$location6 = _vm.location) !== null && _vm$location6 !== void 0 && _vm$location6.address) ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "mr-10"
  }, [_c('i', {
    staticClass: "icon icon-map-marker-outline-sm",
    staticStyle: {
      "width": "16px"
    }
  })]), _c('span', {
    staticClass: "font-size-14 font-size-md-16 grey-2--text"
  }, [_vm._v(_vm._s((_vm$location7 = _vm.location) === null || _vm$location7 === void 0 ? void 0 : _vm$location7.address))])])]) : _vm._e(), !!((_vm$location8 = _vm.location) !== null && _vm$location8 !== void 0 && _vm$location8.homepage) ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "mr-10"
  }, [_c('i', {
    staticClass: "icon icon-home-outline-sm"
  })]), _c('a', {
    staticClass: "font-size-14 font-size-md-16 primary--text",
    attrs: {
      "href": (_vm$location9 = _vm.location) === null || _vm$location9 === void 0 ? void 0 : _vm$location9.homepage,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s((_vm$location10 = _vm.location) === null || _vm$location10 === void 0 ? void 0 : _vm$location10.homepage))])])]) : _vm._e()], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "row-bar-list-wrap mt-18 mt-md-26"
  }, [_c('v-row', {
    staticClass: "row-bar-list font-size-14"
  }, _vm._l(_vm.services, function (item) {
    var _vm$location11, _vm$location11$meta, _vm$location11$meta$r;
    return _c('v-col', {
      key: item.value,
      staticClass: "py-4 font-size-14",
      attrs: {
        "cols": "auto",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.text) + " "), _c('span', {
      staticClass: "grey--text"
    }, [_vm._v(" " + _vm._s(((_vm$location11 = _vm.location) === null || _vm$location11 === void 0 ? void 0 : (_vm$location11$meta = _vm$location11.meta) === null || _vm$location11$meta === void 0 ? void 0 : (_vm$location11$meta$r = _vm$location11$meta.reviewsCount) === null || _vm$location11$meta$r === void 0 ? void 0 : _vm$location11$meta$r[item.value]) || 0) + " ")])]);
  }), 1)], 1), _c('div', {
    staticClass: "mt-36 mt-md-52"
  }, [_c('v-row', [_c('v-spacer'), _c('location-modify-form', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "primary--text",
          attrs: {
            "x-large": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("정보수정 요청하기")])];
      }
    }])
  }, 'location-modify-form', {
    location: _vm.location
  }, false))], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }