var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "color": "white",
      "light": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "size": "150",
      "width": "10"
    }
  })], 1)], 1), _c('v-fade-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }]
  }, [_c('div', [_c('review-list-control', {
    attrs: {
      "isLocationView": ""
    }
  }, [_c('h2', {
    staticClass: "tit tit--sm"
  }, [_vm._v(" 리얼 후기 "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.totalCount))])])]), _c('v-divider', {
    staticClass: "border-2 grey-2 mt-10 mt-md-20"
  })], 1), _c('div', {
    staticClass: "mt-10 mt-md-20"
  }, [_c('review-list-chips', _vm._b({
    attrs: {
      "withCount": "",
      "justify": "start"
    }
  }, 'review-list-chips', {
    summary: _vm.summary
  }, false))], 1), _c('div', {
    staticClass: "mt-26 mt-md-36"
  }, [_c('v-row', _vm._l(_vm.reviews, function (review) {
    return _c('v-col', {
      key: review._id,
      attrs: {
        "cols": "12",
        "md": _vm.cols
      }
    }, [_c('review-item', _vm._b({
      attrs: {
        "isLocationView": "",
        "isBiggerImage": ""
      }
    }, 'review-item', {
      review
    }, false))], 1);
  }), 1), _vm.hasMore ? _c('div', {
    staticClass: "mt-40 mt-md-70"
  }, [_c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-180px",
    attrs: {
      "x-large": "",
      "outlined": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.loadMore
    }
  }, [_vm._v("더 보기")])], 1)]) : _vm._e()], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }