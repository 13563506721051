<template>
    <client-page>
        <sub-visual visual="안과찾기" subTitle="SEARCH" />

        <v-container>
            <v-fade-transition leave-absolute>
                <v-overlay v-show="loading" color="white" light>
                    <v-progress-circular indeterminate color="primary" size="150" width="10" />
                </v-overlay>
            </v-fade-transition>
            <v-expand-transition>
                <location-view-content v-show="!loading && !!location" v-bind="{ location }" />
            </v-expand-transition>

            <location-view-reviews v-if="!loading && !!location" class="mt-40 mt-md-60" />
        </v-container>

        <u-fixed-btn color="primary" :to="`/reviews/write?_location=${_location}`" :activeScrollY="0" large style="border-radius:16px;">
            <i class="icon icon-pencil"></i>
        </u-fixed-btn>
    </client-page>
</template>

<script>
import UFixedBtn from "@/components/dumb/u-fixed-btn.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "../templates/ClientPage.vue";
import LocationViewContent from "@/components/client/locations/location-view-content.vue";
import LocationViewReviews from "@/components/client/locations/location-view-reviews.vue";

import api from "@/api";
import { SERVICES } from "@/assets/variables";
const services = Object.values(SERVICES);

export default {
    components: {
        SubVisual,
        UFixedBtn,
        ClientPage,
        LocationViewContent,
        LocationViewReviews,
    },
    props: {
        _location: String,
    },
    data: () => ({
        loading: false,
        location: null,

        services,
    }),
    mounted() {
        if (this._location) this.search();
        else {
            alert("올바르지 않은 요청입니다.");
            this.$router.push("/locations");
        }
    },
    methods: {
        async search() {
            this.loading = true;
            try {
                const { _location: _id } = this;
                const { location } = await api.v1.eyes.locations.get({ _id });
                this.location = location;
            } catch (error) {
                alert("올바르지 않은 요청입니다.");
                this.$router.push("/locations");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
