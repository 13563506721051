<template>
    <v-row align="center">
        <v-col cols="12" md="7">
            <div class="pr-md-30">
                <v-responsive :aspect-ratio="656 / 390" class="rounded-xl">
                    <location-view-map v-bind="{ location }" />
                </v-responsive>
            </div>
        </v-col>
        <v-col cols="12" md="5">
            <div class="mb-4 mr-n14 mb-md-20">
                <v-row no-gutters align="center">
                    <v-col cols="auto">
                        <strong class="font-size-20 font-size-md-32 grey-2--text">{{ location?.name }}</strong>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn icon class="transparent" @click="share">
                            <i class="icon icon-open-in-new"></i>
                        </v-btn>
                        <v-btn icon class="transparent" :href="`tel:+${location?.phone}`" :disabled="!location?.phone">
                            <i class="icon icon-phone-outline"></i>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <v-divider />
            <div class="py-18 py-md-22">
                <v-row align="center" class="row--small">
                    <v-col cols="12" v-if="!!location?.phone">
                        <div class="d-flex align-center">
                            <span class="mr-10"><i class="icon icon-phone-outline-sm"></i></span>
                            <span class="font-size-14 font-size-md-16 grey-2--text">{{ location?.phone }}</span>
                        </div>
                    </v-col>
                    <v-col cols="12" v-if="!!location?.address">
                        <div class="d-flex align-center">
                            <span class="mr-10"><i class="icon icon-map-marker-outline-sm" style="width:16px;"></i></span>
                            <span class="font-size-14 font-size-md-16 grey-2--text">{{ location?.address }}</span>
                        </div>
                    </v-col>
                    <v-col cols="12" v-if="!!location?.homepage">
                        <div class="d-flex align-center">
                            <span class="mr-10"><i class="icon icon-home-outline-sm"></i></span>
                            <a :href="location?.homepage" target="_blank" class="font-size-14 font-size-md-16 primary--text">{{ location?.homepage }}</a>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-divider />
            <div class="row-bar-list-wrap mt-18 mt-md-26">
                <v-row class="row-bar-list font-size-14">
                    <v-col cols="auto" v-for="item in services" :key="item.value" color="white" class="py-4 font-size-14">
                        {{ item.text }} <span class="grey--text"> {{ location?.meta?.reviewsCount?.[item.value] || 0 }} </span>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-36 mt-md-52">
                <v-row>
                    <v-spacer />
                    <location-modify-form v-bind="{ location }">
                        <template #activator="{attrs,on}">
                            <v-btn x-large class="primary--text" v-bind="attrs" v-on="on">정보수정 요청하기</v-btn>
                        </template>
                    </location-modify-form>
                </v-row>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import KakaoMap from "@/components/plugins/kakao/kakao-map.vue";
import { SERVICES } from "@/assets/variables";
import LocationViewMap from "./location-view-map.vue";
import LocationModifyForm from "./location-modify-form.vue";
const services = Object.values(SERVICES);

export default {
    components: {
        KakaoMap,
        LocationViewMap,
        LocationModifyForm,
    },
    props: {
        location: { type: Object, default: () => ({}) },
    },
    data: () => ({
        services,
    }),
    methods: {
        share() {
            const { phone, address1, address2 } = this.location || {};
            const title = `아이리뷰 - ${this.location?.name}`;
            let text = "";
            if (phone) text += `${phone} / `;
            if (address1) text += `${address1}`;
            if (address2) text += ` ${address2}`;
            const url = `${window.location.origin}/locations/${this.location._id}`;

            navigator.share({ title, text, url });
        },
    },
};
</script>

<style></style>
