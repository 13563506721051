var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "visual": "안과찾기",
      "subTitle": "SEARCH"
    }
  }), _c('v-container', [_c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "color": "white",
      "light": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "size": "150",
      "width": "10"
    }
  })], 1)], 1), _c('v-expand-transition', [_c('location-view-content', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading && !!_vm.location,
      expression: "!loading && !!location"
    }]
  }, 'location-view-content', {
    location: _vm.location
  }, false))], 1), !_vm.loading && !!_vm.location ? _c('location-view-reviews', {
    staticClass: "mt-40 mt-md-60"
  }) : _vm._e()], 1), _c('u-fixed-btn', {
    staticStyle: {
      "border-radius": "16px"
    },
    attrs: {
      "color": "primary",
      "to": `/reviews/write?_location=${_vm._location}`,
      "activeScrollY": 0,
      "large": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-pencil"
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }