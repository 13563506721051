var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.location ? _c('kakao-map', _vm._b({
    attrs: {
      "width": "100%",
      "height": "100%",
      "draggable": false,
      "scrollwheel": false,
      "disableDoubleClick": "",
      "disableDoubleClickZoom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return _c('kakao-map-marker', _vm._g(_vm._b({}, 'kakao-map-marker', Object.assign({}, _vm.props_marker, attrs), false), on));
      }
    }], null, false, 2123594120)
  }, 'kakao-map', _vm.props_map, false)) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }