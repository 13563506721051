<template>
    <div class="px-10 py-20 px-md-20 py-md-30">
        <v-row no-gutters align="center">
            <v-col cols="12" lg="10">
                <div class="font-size-20 font-size-md-24 font-weight-bold grey-2--text mb-8">{{ location.name }}</div>
                <div class="font-size-14 font-size-md-16 grey-7--text mb-6">{{ location.address }}</div>
                <div class="row-bar-list-wrap mb-4">
                    <v-row align="center" class="row-bar-list font-size-14">
                        <v-col cols="auto">
                            <div class="d-flex align-center">
                                <span class="grey-2--text mr-4">후기</span>
                                <span class="grey-a--text">{{ location?._reviews?.length }}</span>
                            </div>
                        </v-col>
                        <v-col cols="auto">
                            <v-rating :value="(location?.meta?.starsAverage?.total || 0) / 10" readonly half-increments dense small color="warning" background-color="warning" />
                        </v-col>
                    </v-row>
                </div>
                <div class="row-bar-list-wrap">
                    <v-row align="center" class="row-bar-list font-size-14">
                        <v-col cols="auto" v-for="item in services" :key="item.value">
                            <div class="d-flex align-center">
                                <span class="grey-2--text mr-4">{{ item.text }}</span>
                                <span class="grey-a--text">{{ location?.meta?.reviewsCount?.[item.value] || 0 }}</span>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="12" lg="2">
                <div class="text-center text-lg-right pt-20 pt-lg-0">
                    <v-btn rounded x-large color="primary" class="w-180px" :to="`/locations/${location._id}`">병원정보</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { SERVICES } from "@/assets/variables";
const services = Object.values(SERVICES);
export default {
    components: {},
    props: {
        location: { type: Object, default: () => ({}) },
    },
    data: () => ({
        services,
    }),
};
</script>

<style></style>
