<template>
    <div>
        <v-fade-transition leave-absolute>
            <v-overlay v-show="loading" color="white" light>
                <v-progress-circular indeterminate color="primary" size="150" width="10" />
            </v-overlay>
        </v-fade-transition>

        <v-fade-transition>
            <div v-show="!loading">
                <div>
                    <review-list-control isLocationView>
                        <h2 class="tit tit--sm">
                            리얼 후기 <span class="primary--text">{{ totalCount }}</span>
                        </h2>
                    </review-list-control>
                    <v-divider class="border-2 grey-2 mt-10 mt-md-20" />
                </div>

                <div class="mt-10 mt-md-20">
                    <review-list-chips withCount v-bind="{ summary }" justify="start" />
                </div>

                <div class="mt-26 mt-md-36">
                    <v-row>
                        <v-col cols="12" :md="cols" v-for="review in reviews" :key="review._id">
                            <review-item v-bind="{ review }" isLocationView isBiggerImage />
                        </v-col>
                    </v-row>

                    <div class="mt-40 mt-md-70" v-if="hasMore">
                        <div class="v-btn--group">
                            <v-btn @click="loadMore" x-large outlined rounded color="primary" class="w-100 mw-180px">더 보기</v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </v-fade-transition>
    </div>
</template>

<script>
import ReviewItem from "@/components/client/reviews/review-item.vue";
import ReviewListChips from "@/components/client/reviews/review-list-chips.vue";
import ReviewListControl from "@/components/client/reviews/review-list-control.vue";
import api from "@/api";

export default {
    components: {
        ReviewItem,
        ReviewListChips,
        ReviewListControl,
    },
    data: () => ({
        loading: false,

        searchKey: null,
        searchValue: null,

        page: 1,
        limit: 10,
        summary: { totalCount: 0 },

        reviews: [],
    }),
    computed: {
        _location() {
            return this.$route.params._location;
        },
        cols() {
            return this.$route.query.cols || 12;
        },
        keyword() {
            return this.$route.query.keyword;
        },
        area() {
            return this.$route.query.gugun || this.$route.query.sido;
        },
        sort() {
            return this.$route.query.sort || "createdAt";
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        totalCount() {
            return this.summary.totalCount;
        },
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        hasMore() {
            return this.totalCount > this.reviews.length;
        },
    },
    mounted() {
        if (this.keyword) this.searchValue = this.keyword;
        this.init().then(this.search);
    },
    watch: {
        keyword() {
            this.searchValue = this.keyword;
            this.init().then(this.search);
        },
        sort() {
            this.init().then(this.search);
        },
        area() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            this.page = 1;
            this.summary = { totalCount: 0 };
            this.reviews = [];
        },
        async search() {
            this.loading = true;

            let { skip, limit, sort, area, searchKey, searchValue, _location } = this;
            const regexp = /^＃([\S]+)/;
            if (regexp.test(searchValue)) {
                searchKey = "services";
                searchValue = searchValue.match(regexp)[1];
            }
            const withServicesCount = true;
            const { summary, reviews } = await api.v1.eyes.reviews.gets({
                headers: { skip, limit },
                params: searchValue ? { _location, sort, area, withServicesCount, searchKey, searchValue } : { _location, sort, area, withServicesCount },
            });

            this.reviews = this.reviews.concat(reviews);
            this.summary = summary;

            this.loading = false;
        },
        loadMore() {
            this.page += 1;
            this.search();
        },
    },
};
</script>

<style></style>
