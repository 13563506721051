<template>
    <dialog-window v-model="showsDialog" title="병원 정보 수정 요청" :max-width="isLoggedIn ? 660 : 400" persistent>
        <template #activator="{attrs,on}">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <template v-if="isLoggedIn">
            <v-card-text class="px-30 py-0" style="max-height: calc(90vh - 50px - 80px); overflow-y: auto">
                <div class="table-style ">
                    <myinfo-modify-row label="병원명" pointer>
                        <v-text-field v-model="form.meta.name" type="text" v-bind="inputAttrs" />
                    </myinfo-modify-row>
                    <myinfo-modify-row label="연락처">
                        <v-text-field v-model="form.meta.phone" type="text" v-bind="inputAttrs" />
                    </myinfo-modify-row>
                    <myinfo-modify-row label="이메일">
                        <v-text-field v-model="form.meta.email" type="text" v-bind="inputAttrs" />
                    </myinfo-modify-row>
                    <myinfo-modify-row label="사이트">
                        <v-text-field v-model="form.meta.homepage" type="text" v-bind="inputAttrs" append-icon="mdi-link" @click:append="window.open(form.meta.homepage, '_blank')" />
                    </myinfo-modify-row>
                    <myinfo-modify-row label="주소">
                        <div class="mw-lg-500px">
                            <v-row class="row--x-small" align="center">
                                <v-col cols="9">
                                    <v-text-field v-model="form.meta.postcode" type="text" readonly placeholder="우편번호" outlined hide-details @click="$refs.daumPostcode.open()" />
                                </v-col>
                                <v-col cols="3">
                                    <v-btn @click="$refs.daumPostcode.open()" tile color="primary" class="w-100 v-btn--input">검색</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="form.meta.address1" type="text" readonly placeholder="기본주소" outlined hide-details @click="$refs.daumPostcode.open()" />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="form.meta.address2" type="text" placeholder="상세주소" outlined hide-details />
                                </v-col>
                            </v-row>
                        </div>
                    </myinfo-modify-row>
                </div>
                <daum-postcode ref="daumPostcode" @change="({ postcode, address }) => Object.assign(form.meta, { postcode, address1: address })"></daum-postcode>
            </v-card-text>
            <v-card-actions class="px-30 py-16 justify-center">
                <v-btn x-large rounded color="primary" class="w-100 mw-180px" @click="save">요청하기</v-btn>
            </v-card-actions>
        </template>
        <template v-else>
            <v-card-text class="px-30 py-30 font-size-24" style="max-height: calc(90vh - 50px - 80px); overflow-y: auto; text-align: center;">
                로그인이 필요한 메뉴입니다.
            </v-card-text>
            <v-card-actions class="px-30 pb-16 justify-center">
                <v-btn x-large rounded color="primary" class="w-100 mw-180px" to="/login">로그인하기</v-btn>
            </v-card-actions>
        </template>
    </dialog-window>
</template>

<script>
import FormRow from "@/components/dumb/form-row.vue";
import DialogWindow from "@/components/dumb/dialog-window.vue";
import MyinfoModifyRow from "../mypage/myinfo-modify-row.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import api from "@/api";

const inputAttrs = {
    outlined: true,
    "hide-details": true,
    class: "w-100 mw-lg-500px",
};

export default {
    components: {
        FormRow,
        DialogWindow,
        MyinfoModifyRow,
        DaumPostcode,
    },
    props: {
        location: { type: Object, default: () => ({}) },
    },
    data: () => ({
        window,
        inputAttrs,
        showsDialog: false,
        ready: false,
        form: {
            code: "location-modify-request",
            meta: {
                name: null,
                phone: null,
                email: null,
                homepage: null,
                postcode: null,
                address1: null,
                address2: null,
            },
        },
    }),
    computed: {
        isLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
    },
    watch: {
        showsDialog() {
            this.init();
        },
    },
    methods: {
        init() {
            const { _id: _location, name, phone, email, homepage, postcode, address1, address2 } = this.location || {};
            this.form = JSON.parse(JSON.stringify({ code: "location-modify-request", meta: { _location, name, phone, email, homepage, postcode, address1, address2 } }));
        },
        validate() {
            let isValid = undefined;
            try {
                const { code, meta } = this.form || {};

                if (!this.isLoggedIn) throw new Error("로그인이 필요한 메뉴입니다.");

                if (code != "location-modify-request" || !meta || !meta?._location) throw new Error("잘못된 요청입니다.");
                if (!meta?.name || (meta?.name?.length || 0) < 1) throw new Error("병원명은 필수값입니다.");

                isValid = true;
            } catch (error) {
                alert(error.message);
                isValid = false;
            } finally {
                return isValid;
            }
        },
        async save() {
            const isValid = this.validate();
            if (!isValid) return;

            const { form } = await api.v1.forms.post(this.form);
            alert("성공적으로 요청되었습니다.");
            this.showsDialog = false;
        },
    },
};
</script>

<style></style>
