<template>
    <v-btn-toggle v-model="sort" mandatory borderless active-class="active" class="mr-n12">
        <v-btn value="reviewedAt" text x-small class="v-btn--dot">최신순</v-btn>
        <v-btn value="meta.starsAverage.total" text x-small class="v-btn--dot">추천순</v-btn>
        <v-btn value="meta.reviewsCount.total" text x-small class="v-btn--dot">후기순</v-btn>
    </v-btn-toggle>
</template>

<script>
export default {
    data: () => ({
        sort: "reviewedAt",
    }),
    computed: {},
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query.sort"() {
            this.sync();
        },
        sort() {
            let { sort } = this;
            if (sort == "reviewedAt") sort = undefined;
            if (sort !== this.$route.query.sort) {
                this.$router.push({ query: { ...this.$route.query, sort } });
            }
        },
    },
    methods: {
        sync() {
            this.sort = this.$route.query.sort || "reviewedAt";
        },
    },
};
</script>

<style lang="scss" scoped>
</style>