var render = function render(){
  var _vm$location, _vm$location$_reviews, _vm$location2, _vm$location2$meta, _vm$location2$meta$st;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-10 py-20 px-md-20 py-md-30"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "10"
    }
  }, [_c('div', {
    staticClass: "font-size-20 font-size-md-24 font-weight-bold grey-2--text mb-8"
  }, [_vm._v(_vm._s(_vm.location.name))]), _c('div', {
    staticClass: "font-size-14 font-size-md-16 grey-7--text mb-6"
  }, [_vm._v(_vm._s(_vm.location.address))]), _c('div', {
    staticClass: "row-bar-list-wrap mb-4"
  }, [_c('v-row', {
    staticClass: "row-bar-list font-size-14",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "grey-2--text mr-4"
  }, [_vm._v("후기")]), _c('span', {
    staticClass: "grey-a--text"
  }, [_vm._v(_vm._s((_vm$location = _vm.location) === null || _vm$location === void 0 ? void 0 : (_vm$location$_reviews = _vm$location._reviews) === null || _vm$location$_reviews === void 0 ? void 0 : _vm$location$_reviews.length))])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-rating', {
    attrs: {
      "value": (((_vm$location2 = _vm.location) === null || _vm$location2 === void 0 ? void 0 : (_vm$location2$meta = _vm$location2.meta) === null || _vm$location2$meta === void 0 ? void 0 : (_vm$location2$meta$st = _vm$location2$meta.starsAverage) === null || _vm$location2$meta$st === void 0 ? void 0 : _vm$location2$meta$st.total) || 0) / 10,
      "readonly": "",
      "half-increments": "",
      "dense": "",
      "small": "",
      "color": "warning",
      "background-color": "warning"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "row-bar-list-wrap"
  }, [_c('v-row', {
    staticClass: "row-bar-list font-size-14",
    attrs: {
      "align": "center"
    }
  }, _vm._l(_vm.services, function (item) {
    var _vm$location3, _vm$location3$meta, _vm$location3$meta$re;
    return _c('v-col', {
      key: item.value,
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "d-flex align-center"
    }, [_c('span', {
      staticClass: "grey-2--text mr-4"
    }, [_vm._v(_vm._s(item.text))]), _c('span', {
      staticClass: "grey-a--text"
    }, [_vm._v(_vm._s(((_vm$location3 = _vm.location) === null || _vm$location3 === void 0 ? void 0 : (_vm$location3$meta = _vm$location3.meta) === null || _vm$location3$meta === void 0 ? void 0 : (_vm$location3$meta$re = _vm$location3$meta.reviewsCount) === null || _vm$location3$meta$re === void 0 ? void 0 : _vm$location3$meta$re[item.value]) || 0))])])]);
  }), 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "text-center text-lg-right pt-20 pt-lg-0"
  }, [_c('v-btn', {
    staticClass: "w-180px",
    attrs: {
      "rounded": "",
      "x-large": "",
      "color": "primary",
      "to": `/locations/${_vm.location._id}`
    }
  }, [_vm._v("병원정보")])], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }