<template>
    <client-page>
        <sub-visual visual="안과찾기" subTitle="SEARCH" />

        <v-container>
            <div>
                <location-list-control>
                    <v-text-field v-model="searchValue" outlined prepend-inner-icon="mdi-magnify" hide-details @keydown.enter="go" />
                </location-list-control>
                <v-divider class="border-2 grey-2 mt-10 mt-md-20" />
            </div>

            <div>
                <v-row no-gutters>
                    <v-col cols="12" :md="cols" v-for="location in locations" :key="location._id">
                        <location-item v-bind="{ location }" />
                        <v-divider />
                    </v-col>
                </v-row>
            </div>

            <div class="mt-40 mt-md-70" v-if="hasMore">
                <div class="v-btn--group">
                    <v-btn @click="loadMore" x-large outlined rounded color="primary" class="w-100 mw-180px">더 보기</v-btn>
                </div>
            </div>
        </v-container>

        <!-- list -->
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "../templates/ClientPage.vue";
import LocationItem from "@/components/client/locations/location-item.vue";
import LocationListControl from "@/components/client/locations/location-list-control.vue";
import api from "@/api";

export default {
    components: {
        SubVisual,
        ClientPage,
        LocationItem,
        LocationListControl,
    },
    data: () => ({
        searchKey: null,
        searchValue: null,

        page: 1,
        limit: 10,
        totalCount: 0,

        locations: [],
    }),
    computed: {
        cols() {
            return this.$route.query.cols || 12;
        },
        keyword() {
            return this.$route.query.keyword;
        },
        area() {
            return this.$route.query.gugun || this.$route.query.sido;
        },
        sort() {
            return this.$route.query.sort || "reviewedAt";
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        hasMore() {
            return this.totalCount > this.locations.length;
        },
    },
    mounted() {
        if (this.keyword) this.searchValue = this.keyword;
        this.init().then(this.search);
    },
    watch: {
        keyword() {
            this.searchValue = this.keyword;
            this.init().then(this.search);
        },
        sort() {
            this.init().then(this.search);
        },
        area() {
            this.init().then(this.search);
        },
    },
    methods: {
        go() {
            let { searchValue: keyword } = this;
            if (!keyword) keyword = undefined;
            this.$router.push({ query: { ...this.$route.query, keyword } });
        },
        async init() {
            this.page = 1;
            this.totalCount = 0;
            this.locations = [];
        },
        async search() {
            let { skip, limit, sort, area, searchKey, searchValue } = this;
            const regexp = /^＃([\S]+)안과/;
            if (regexp.test(searchValue)) {
                searchKey = "address";
                searchValue = searchValue.match(regexp)[1];
            }
            const {
                summary: { totalCount },
                locations,
            } = await api.v1.eyes.locations.gets({
                headers: { skip, limit },
                params: searchValue ? { sort, area, searchKey, searchValue } : { sort, area },
            });

            this.locations = this.locations.concat(locations);
            this.totalCount = totalCount;
        },
        loadMore() {
            this.page += 1;
            this.search();
        },
    },
};
</script>

<style></style>
