<template></template>

<script>
// import { HONJA_LOCATION_TYPES } from "@/assets/variables";
export default {
    props: {
        lat: { type: Number, default: null },
        lng: { type: Number, default: null },
        name: { type: String, default: null },
        address: { type: String, default: null },

        content: {
            type: String,
            default: `<div class="customoverlay">
    <div class="customoverlay__con">
        <a href="https://map.kakao.com/link/map/11394059" target="_blank" class="customoverlay__tit">
            <span>어덜트월드</span>
            <i class="icon icon-chevron-right-small"></i>
        </a>
        <div class="customoverlay__txt">
            <a href="/honja/milestones"><span>연관 포스팅</span><span class="primary--text ml-4">12</span></a>
        </div>
    </div>
</div>`,
        },

        selectedMarker: { type: Object, default: null },
        clickedOverlay: { type: Object, default: null },
    },
    data() {
        return {
            marker: null,
            overay: null,
            position: null,
        };
    },
    computed: {
        map() {
            return this.$parent.map;
        },
        imageIndex() {
            switch (this.$attrs.type) {
                // case HONJA_LOCATION_TYPES.ACCOMMODATION.value:
                //     return 0;
                // case HONJA_LOCATION_TYPES.RESTAURANT.value:
                //     return 1;
                // case HONJA_LOCATION_TYPES.HOBBY.value:
                //     return 2;
                // case HONJA_LOCATION_TYPES.ETC.value:
                //     return 3;
                default:
                    return 0;
            }
        },
    },
    watch: {
        address() {
            this.marking();
        },
        lat() {
            this.marking();
        },
        lng() {
            this.marking();
        },
    },
    destroyed() {
        this.marker.setMap(null);
    },
    mounted() {
        this.marking();
    },
    methods: {
        marking() {
            // 기존 마커 제거
            if (this.marker) {
                this.marker.setMap(null);
            }

            // console.log({ ...this.$props });
            // console.log({ ...this.$parent.$data });

            // 위도 & 경도로 마커생성
            if (this.lat && this.lng) {
                this.position = new kakao.maps.LatLng(this.lat, this.lng);
                this.marker = new kakao.maps.Marker({ position: this.position });

                if (this.$parent?.usesCustomMarker) this.setCustomMarker();
                else this.setDefaultMarker();
                // console.log({ ...this.$data });
            }
            // 주소로 마커생성
            else if (this.address) {
                this.$parent.geocoder.addressSearch(this.address, (result, status) => {
                    // 정상적으로 검색이 완료됐으면
                    if (status === kakao.maps.services.Status.OK) {
                        this.position = new kakao.maps.LatLng(result[0].y, result[0].x);
                        this.marker = new kakao.maps.Marker({ position: this.position });

                        if (this.$parent?.usesCustomMarker) this.setCustomMarker();
                        else this.setDefaultMarker();
                        // console.log({ ...this.$data });
                    }
                });
            }
        },

        setDefaultMarker() {
            const { name, address } = this;
            let title = "";
            if (!!name && !!address) title = `${name} (${address})`;
            else if (!!name) title = name;
            else if (!!address) title = address;

            const href = `https://map.kakao.com/link/map/${title},${this.lat},${this.lng}`;
            kakao.maps.event.addListener(this.marker, "click", function() {
                window.open(href, "_blank");
            });

            this.marker.setMap(this.map);
        },

        setCustomMarker() {
            const { overMarkerSize, overMarkerOffset, clickMarkerSize, clickMarkerOffset, markerSize, markerOffset, originY, gapX, clickOriginY, overOriginY } = { ...(this.$parent.customMarkerProps || {}), ...(this.$parent.customMarkerComputed || {}) };

            const { imageIndex } = this;

            const normalOrigin = new kakao.maps.Point(0, originY * imageIndex); // 스프라이트 이미지에서 기본 마커로 사용할 영역의 좌상단 좌표
            const clickOrigin = new kakao.maps.Point(gapX, clickOriginY * imageIndex); // 스프라이트 이미지에서 클릭 마커로 사용할 영역의 좌상단 좌표
            const overOrigin = new kakao.maps.Point(0, overOriginY * imageIndex); // 스프라이트 이미지에서 마우스오버 마커로 사용할 영역의 좌상단 좌표

            this.marker.image_over = this.createMarkerImage(overMarkerSize, overMarkerOffset, overOrigin);
            this.marker.image_click = this.createMarkerImage(clickMarkerSize, clickMarkerOffset, clickOrigin);
            this.marker.image_normal = this.createMarkerImage(markerSize, markerOffset, normalOrigin);

            this.marker.setImage(this.marker.image_normal);
            this.marker.setClickable(true);
            this.marker.setMap(this.map);

            const { content, position } = this;
            this.overlay = new kakao.maps.CustomOverlay({ content, position, yAnchor: 1 });

            kakao.maps.event.addListener(this.marker, "mouseover", () => {
                // 클릭된 마커가 없고, mouseover된 마커가 클릭된 마커가 아니면
                // 마커의 이미지를 오버 이미지로 변경합니다
                if (!this.selectedMarker || this.selectedMarker !== this.marker) {
                    this.marker.setImage(this.marker.image_over);
                }
            });

            kakao.maps.event.addListener(this.marker, "mouseout", () => {
                // 클릭된 마커가 없고, mouseout된 마커가 클릭된 마커가 아니면
                // 마커의 이미지를 기본 이미지로 변경합니다
                if (!this.selectedMarker || this.selectedMarker !== this.marker) {
                    this.marker.setImage(this.marker.image_normal);
                }
            });

            kakao.maps.event.addListener(this.marker, "click", () => {
                console.log({ thisMarker: this.marker, selectedMarker: this.selectedMarker, isSame: this.marker == this.selectedMarker });
                // 클릭된 마커가 없고, click 마커가 클릭된 마커가 아니면
                // 마커의 이미지를 클릭 이미지로 변경합니다
                if (!this.selectedMarker || this.selectedMarker !== this.marker) {
                    // 클릭된 마커 객체가 null이 아니면
                    // 클릭된 마커의 이미지를 기본 이미지로 변경하고
                    if (this.selectedMarker) this.selectedMarker.setImage(this.selectedMarker.image_normal);

                    // 현재 클릭된 마커의 이미지는 클릭 이미지로 변경합니다
                    this.marker.setImage(this.marker.image_click);
                    this.$emit("setSelectedMarker", this.marker);
                } else if (this.selectedMarker == this.marker) {
                    this.marker.setImage(this.marker.image_normal);
                    this.$emit("setSelectedMarker", null);
                }

                // 커스텀 오버레이 닫기
                if (this.clickedOverlay) {
                    this.clickedOverlay.setMap(null);
                }
                // 클릭된 커스텀 오버레이 열기
                this.overlay.setMap(this.map);
                this.$emit("setClickedOverlay", this.overlay);
            });
        },

        createMarkerImage(markerSize, offset, spriteOrigin) {
            return new kakao.maps.MarkerImage(
                this.$parent.customMarkerProps.SPRITE_MARKER_URL, // 스프라이트 마커 이미지 URL
                markerSize, // 마커의 크기
                {
                    offset: offset, // 마커 이미지에서의 기준 좌표
                    spriteOrigin: spriteOrigin, // 스트라이프 이미지 중 사용할 영역의 좌상단 좌표
                    spriteSize: this.$parent.customMarkerComputed.spriteImageSize, // 스프라이트 이미지의 크기
                }
            );
        },
    },
};
</script>
