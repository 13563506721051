var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "visual": "안과찾기",
      "subTitle": "SEARCH"
    }
  }), _c('v-container', [_c('div', [_c('location-list-control', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-magnify",
      "hide-details": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.go.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  })], 1), _c('v-divider', {
    staticClass: "border-2 grey-2 mt-10 mt-md-20"
  })], 1), _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.locations, function (location) {
    return _c('v-col', {
      key: location._id,
      attrs: {
        "cols": "12",
        "md": _vm.cols
      }
    }, [_c('location-item', _vm._b({}, 'location-item', {
      location
    }, false)), _c('v-divider')], 1);
  }), 1)], 1), _vm.hasMore ? _c('div', {
    staticClass: "mt-40 mt-md-70"
  }, [_c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-180px",
    attrs: {
      "x-large": "",
      "outlined": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.loadMore
    }
  }, [_vm._v("더 보기")])], 1)]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }