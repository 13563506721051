<template>
    <kakao-map v-if="location" v-bind="props_map" width="100%" height="100%" :draggable="false" :scrollwheel="false" disableDoubleClick disableDoubleClickZoom>
        <kakao-map-marker slot-scope="{ on, attrs }" v-on="on" v-bind="{ ...props_marker, ...attrs }" />
    </kakao-map>
</template>

<script>
import KakaoMap from "@/components/plugins/kakao/kakao-map.vue";
import KakaoMapMarker from "@/components/plugins/kakao/kakao-map-marker.vue";
export default {
    components: {
        KakaoMap,
        KakaoMapMarker,
    },
    props: {
        location: { type: Object, default: () => ({}) },
    },
    computed: {
        props_map() {
            const [lng, lat] = this.location?.geolocation?.coordinates || [];

            return {
                width: "100%",
                height: "37vh",
                centerPosition: { lng, lat },
            };
        },
        props_marker() {
            return {
                ...this.location,
                lat: this.location.geolocation.coordinates[1],
                lng: this.location.geolocation.coordinates[0],
                address: `${this.location.address1} ${this.location.address2}`,
                content: "",
            };
        },
    },
};
</script>

<style></style>
